import React from 'react';
import { graphql } from 'gatsby';
import NewsPageView from '../../views/newspage-view';

const Page = ({ data }) => {
    console.log(data);
    return (
      <>
        <NewsPageView posts={data} />
      </>
    );
};
export default Page

export const query = graphql`query {
  allMarkdownRemark(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {layout: {ne: "dontremove"}}}
    ) {
    edges {
      node {
        id
        frontmatter {
          coverImage {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.5
                placeholder: BLURRED
                width: 1200
              )
            }
          }
          layout
          title
          date(formatString: "DD MMMM, YYYY")
          ingress
          lang
        }
        excerpt(truncate: true)
      }
    }
  }
  allSitePage {
    edges {
      node {
        pageContext
        path
      }
    }
  }
}`