
import { css } from 'styled-components'

const fontColor = css`
  color: ${ ({lightBg}) => (lightBg ? '#f7f8fa' : '#010606') || '#010606'};
`;
export const regularFont = css`
  font-size: 0.85rem;
  line-height: 1.35rem;
  ${fontColor}
`;
export const dateFont = css`
  font-size: 0.5rem;
  font-weight: 700;
  letter-spacing: 2px;
`;
export const headingFont = css`
  font-size: 1.5rem;
  font-weight: 600;
  ${fontColor}
`;

// News page 'grid-template-areas:'
export const gridRegular = css`
  grid-template-areas: 'image header' 'image ingress' 'image ingress' 'image button';
`;
export const gridReversed = css`
  grid-template-areas: 'header image' 'ingress image' 'ingress image' 'button image';
`;
export const gridSingular = css`
  grid-template-areas: 'image image' 'header header' 'ingress ingress' 'button button';
`;
