import React from 'react';
import {
  InfoContainer, InfoWrapper, Row, Column, Date, Heading, Ingress,
  Img, Button, ButtonWrapper, ReadMore 
} from '../styles/StyledNewsPage';
const NewsPageView = ({ posts }) => {
  const { allMarkdownRemark: { edges }} = posts;
  const { allSitePage: { edges: pageEdges }} = posts;
  const postsPage = [];
  var isStart = false;

  for (const { node } of edges) {
      const { id, frontmatter: { date, title, ingress, coverImage }} = node;
      const { childImageSharp: { gatsbyImageData: { images: { fallback: { sizes, src, srcSet }}}} } = coverImage;

      // Get the path of markdown file from allSitePage data
      var pagePath = '';
      for (const { node } of pageEdges) {
        // if (page id) node.pageContext.id === (markdown id) outer id variable
        // set pagePath to node.pageContext.__params.fronmatter__title; the reason
        // for setting to it is because it's a sluggified version of the md title
        // which can be used as a relative link
        if (node.pageContext.id === id) {
            // use pagePath as a relative link to the individual article
            pagePath = node.pageContext.__params.frontmatter__title
        };
      };
      postsPage.push(
        <>
          <Row isStart={isStart}>
            <Column colName='image'>
              <Img src={src} srcSet={srcSet} sizes={sizes} alt={title} title={title} lightBg={false}/>
            </Column>

            <Column colName='header'>
                <Heading lightBg={false}> {title} </Heading>
            </Column>
            <Column colName='ingress'>
                <Ingress lightBg={false}> {ingress} </Ingress>
            </Column>

            <Column colName='button'>
              <ButtonWrapper>
                <Date> {date} </Date>
                <Button to={pagePath}>Read more<ReadMore /></Button>
              </ButtonWrapper>
            </Column>
          </Row>
        </>
      );
      isStart = !isStart;
  };
  return ( 
    <InfoContainer lightBg={false}>
      <InfoWrapper>
        {postsPage} 
      </InfoWrapper>
    </InfoContainer>
  )
};
export default NewsPageView