//Z
import {Link} from 'gatsby';
import styled from 'styled-components';
import { MdReadMore, MdOutlineReadMore, } from 'react-icons/md';
import { regularFont, dateFont, headingFont, gridRegular, gridReversed, gridSingular } from './StyledFontMixins';

export const InfoContainer = styled.div`
  background: ${({lightBg}) => (lightBg ? 'var(--anzr-blue)' : '#f9f9f9')};
  margin-bottom: 100px;
  margin-top: 200px;
`
export const InfoWrapper = styled.div`
  display: grid;
  grid-gap: 5rem 0;
  z-index: 1;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 24px;
  justify-content: center;
`
export const Row = styled.div`
  display: grid;
  grid-gap: 0 2rem;
  grid-auto-columns: repeat(auto, minmax(360px, 1fr));
  ${({isStart}) => ( isStart ? `${gridRegular}` : `${gridReversed}` )};

  @media screen and (max-width: 920px) {
    ${gridSingular};
  };
`

export const Column = styled.div`
  grid-area: ${props => props.colName};
  ${({colName}) => (colName === 'button' ? `text-align: right; padding-right: 6px;` : ``)}
  min-width: 360px;
  max-width: 655px;
`
export const Date = styled.p`
  margin-left: 0px;
  text-transform: uppercase;
  ${dateFont};
`
export const Heading = styled.h1`
  margin-top: 1em;
  ${headingFont};
`
export const Ingress  = styled.p`
  margin-top: 1em;
  ${regularFont};
`
export const Paragraph  = styled.p`
  margin-bottom: ${({isTitle}) => (isTitle ? '4px' : '35px')};
  font-size: 0.85rem;
  line-height: 1.35rem;
  font-weight: ${({isTitle}) => (isTitle ? 'bold' : 'normal')};
`
export const Img = styled.img`
  /* border-radius: 2% 10% 2% 10%; */
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  /* ${({lightBg}) => (
    lightBg ? 'filter: drop-shadow(0mm 4mm 6mm rgba(10, 10, 10, 70% ))'
            : 'filter: drop-shadow(0mm 4mm 6mm rgba(10, 10, 10, 70% ))'
  )}; */
  
`
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    ${headingFont};
    color: var(--anzr-light-blue);

`
export const Button = styled(Link)`
    color: var(--anzr-light-blue);
    font-size: 0.7rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition-duration: 0.4s;

    &:hover {
        color: var(--anzr-light-green);
    };
`;
export const ReadMore = styled(MdReadMore)`
    font-size: 38px;
    padding-right: 4px;
`;
export const ReadMoreOut = styled(MdOutlineReadMore)`
    font-size: 38px;
    padding-right: 4px;
`;